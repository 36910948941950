
import NotAllowedScreen from 'components/layout/NotAllowedScreen';

const Page404 = () => {

    return (
        <>
            <NotAllowedScreen statusCode={404}/>
        </>
    );
};

export default Page404;
